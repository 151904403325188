<template>
	<div class="mail">
		<form class="form">
			<div class="form-group">
				<label>Vendor*</label>

				<v-select
					v-model="searchForm.vendor"
					:items="vendors"
					dense
					outlined
					hide-details="auto"
					height="26px"
					style="width: 260px; max-width: 260px"
				></v-select>
			</div>

			<div class="form-group">
				<label>Start Month*</label>

				<v-select
					v-model="searchForm.startMonth"
					:items="months"
					dense
					outlined
					hide-details="auto"
					height="26px"
					style="max-width: 100px"
				></v-select>
			</div>

			<div class="form-group">
				<label>End Month*</label>

				<v-select
					v-model="searchForm.endMonth"
					:items="months"
					dense
					outlined
					hide-details="auto"
					height="26px"
					style="max-width: 100px"
				></v-select>
			</div>

			<div class="form-group">
				<label>Year*</label>

				<v-select
					v-model="searchForm.year"
					:items="years"
					dense
					outlined
					hide-details="auto"
					height="26px"
					style="max-width: 100px"
				></v-select>
			</div>

			<div class="form-group">
				<label>&nbsp;</label>
				<button type="button" class="form-btn" @click="searchMail()">Search</button>
			</div>
		</form>

		<table style="border-left: none" class="rslt mt-2" cellspacing="0" width="100%">
			<tbody>
				<tr>
					<th align="left">Mail ID</th>
					<th align="center">Date</th>
					<th align="left">Recipients</th>
					<th align="left">Subject</th>
				</tr>
				<tr v-for="mail in mailData" :key="mail.mailLogId">
					<td align="left">
						<button type="button" class="form-btn-link" @click="routeToMessage(mail)">
							{{ mail.mailLogId }}
						</button>
					</td>
					<td align="center">{{ mail.sentDateTime }}</td>
					<td align="left">{{ mail.toRecipients }}</td>
					<td align="left">{{ mail.subject }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "Mail",
	data() {
		return {
			searchForm: {
				vendor: "",
				startMonth: "",
				endMonth: "",
				year: "",
			},
			vendors: ["All Vendors"],
			months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
			years: [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025],
			formError: false,
			mailData: [],
		};
	},
	methods: {
		async searchMail() {
			let request = {
				vendor: this.searchForm.vendor,
				startMonth: this.months.findIndex((month) => month === this.searchForm.startMonth) + 1,
				endMonth: this.months.findIndex((month) => month === this.searchForm.endMonth) + 1,
				year: this.searchForm.year,
			};

			try {
				const res = await this.$http.post(`/api/mail/list`, request);
				this.mailData = res.data;
			} catch (error) {
				console.log(error);
			}
		},

		routeToMessage(data) {
			this.$router.push({
				name: "MailMessage",
				params: { mail: data },
			});
		},
	},
	mounted() {
		this.searchForm.vendor = this.vendors[0];
		this.searchForm.startMonth = this.months[new Date().getMonth()];
		this.searchForm.endMonth = this.months[new Date().getMonth()];
		this.searchForm.year = new Date().getFullYear();
	},
	beforeCreate() {
		if (!this.$store.getters.isAdmin) {
			this.$router.push("/product-search");
		}
	},
	watch: {
		$route(to, from) {
			console.log("WATCH", to, from);

			if (to.path == "/mail" && from.path != "/mail-message") {
				this.mailData = [];
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.mail {
	position: relative;

	.form-btn {
		width: inherit;
		font-weight: 700;
		margin-top: 0.2rem;
	}
}
</style>
